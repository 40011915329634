<template>
  <div>
    <div class="row">
      <div class="col">
        <h4>SALIDAS DE ALMACEN</h4>
        <app-table-registers
          ref="tr"
          :getList="getList"
          @btnNew="
            $refs.dFormCreat.show();
            $refs.formCre.reset();
          "
        >
          <!-- <template slot="afterNewBtn">
            <SelectStore v-model="store_id"></SelectStore>
          </template> -->
          <template slot="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>FECHA</th>
                <th>ALMACEN</th>
                <th>CANTIDAD</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="l in list.data" :key="l.id">
                <td>{{ l.id }}</td>
                <td>{{ l.created_at }}</td>
                <td>{{ l.store_name }}</td>
                <td>{{ l.total_quantity }}</td>
                <td>
                  <div class="btn-group">
                    <router-link
                      :to="'/logistic/outputs/' + l.id"
                      class="btn btn-light btn-sm"
                      tag="a"
                      title="Editar/ver"
                    >
                      <i class="fa fa-edit"></i>
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </app-table-registers>
      </div>
    </div>

    <app-modal-basic ref="dFormCreat">
      <Form
        ref="formCre"
        @submitted="$router.push('/logistic/outputs/' + $event)"
      ></Form>
    </app-modal-basic>
  </div>
</template>

<script>
import { LogisticService } from "../service";
import Form from "./FormCreate.vue";
// import SelectStore from "../stores/Select";

export default {
  components: {
    Form,
    // SelectStore
  },
  data: () => ({
    list: {},
    store_id: undefined
  }),
  methods: {
    getList({ page, search }) {
      return new Promise(rsv => {
        LogisticService.getOutputs({ page, search }).then(res => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
